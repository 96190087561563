import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

const About = () => {
  useEffect(() => {
    ReactGA.event({
      category: "LoadPage",
      action: "About",
    });
  }, []);

  return (
    <div className="grid grid-cols-1 nav:grid-cols-2">
      <div className="flex items-center nav:items-end min-h-screen w-full bg-about-top-bg bg-no-repeat bg-center bg-cover text-white bg-primary-blue z-1">
        <svg
          className="m-auto nav:ml-20 nav:mb-20"
          width="246"
          height="289"
          viewBox="0 0 146 189"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M109.415 9.64537C103.873 12.8163 98.7769 16.7009 94.2599 21.1983C81.7676 33.592 74.2222 50.0625 73.0216 67.5582C71.8211 50.0625 64.2756 33.592 51.7833 21.1983C38.0561 7.60111 19.4524 -0.0239581 0.0644291 0.000327494V21.4102H0.192494C13.2761 21.415 25.872 26.3422 35.4444 35.1997C45.0169 44.0572 50.8549 56.1874 51.7833 69.1481C51.8687 70.3069 51.9114 71.4728 51.9114 72.6457C51.9114 73.8752 51.8687 75.0835 51.7833 76.2705C50.9143 88.5569 45.6087 100.119 36.8418 108.831L36.7138 108.979L36.5644 109.106C27.8149 117.783 16.2172 123.049 3.88514 123.945C2.64713 123.945 1.40916 124.072 0.128462 124.072H0.000396729V144.846H7.38575C17.702 143.847 27.6818 140.659 36.6497 135.498C42.1833 132.314 47.2775 128.431 51.8047 123.945C56.3216 119.449 60.232 114.39 63.4377 108.894C69.0057 99.3235 72.2916 88.6129 73.043 77.5848C74.2435 95.0805 81.7889 111.551 94.2812 123.945C98.804 128.436 103.899 132.319 109.436 135.498C118.398 140.656 128.37 143.844 138.679 144.846H146V124.072H145.851C144.591 124.072 143.332 124.072 142.094 123.945C129.769 123.044 118.18 117.779 109.436 109.106L109.287 108.979L109.159 108.831C100.382 100.125 95.0748 88.5597 94.2172 76.2705C94.1105 75.0835 94.0678 73.8752 94.0678 72.6457C94.0678 71.4798 94.0678 70.3139 94.2172 69.1481C95.1453 56.191 100.98 44.0639 110.548 35.2069C120.116 26.3499 132.707 21.4203 145.787 21.4102H145.936V0.000327494C133.119 -0.0380053 120.52 3.28941 109.415 9.64537Z"
            fill="white"
          />
          <path
            d="M15.7554 170.037V173.664H10.0227V188.93H5.7327V173.664H0V170.037H15.7554Z"
            fill="white"
          />
          <path
            d="M36.7597 178.968C36.7881 180.437 36.5257 181.898 35.9861 183.274C35.5274 184.439 34.8153 185.499 33.8965 186.384C32.9988 187.232 31.9193 187.884 30.732 188.298C29.4646 188.725 28.1285 188.939 26.7839 188.929C25.4623 188.938 24.1496 188.724 22.9061 188.298C21.7139 187.882 20.6283 187.23 19.7214 186.384C18.7836 185.504 18.054 184.444 17.5816 183.274C17.0369 181.899 16.7743 180.437 16.8081 178.968C16.7793 177.493 17.0523 176.027 17.6118 174.652C18.1066 173.479 18.8524 172.416 19.8018 171.533C20.7034 170.685 21.7862 170.032 22.9764 169.619C24.1963 169.195 25.4854 168.981 26.7839 168.987C28.1021 168.979 29.4116 169.193 30.6516 169.619C31.8471 170.028 32.9341 170.681 33.8363 171.533C34.7699 172.419 35.4987 173.482 35.9761 174.652C36.5238 176.03 36.7898 177.495 36.7597 178.968ZM21.4594 178.968C21.4477 179.822 21.5696 180.674 21.8211 181.494C22.0229 182.188 22.3641 182.838 22.8257 183.408C23.2655 183.946 23.8338 184.375 24.4833 184.661C25.1918 184.971 25.9644 185.125 26.7437 185.111C27.5161 185.12 28.2809 184.966 28.984 184.661C29.6412 184.379 30.2168 183.949 30.6617 183.408C31.1195 182.836 31.4603 182.186 31.6663 181.494C31.9178 180.674 32.0396 179.822 32.0279 178.968C32.0394 178.107 31.9176 177.249 31.6663 176.422C31.4643 175.714 31.1235 175.048 30.6617 174.461C30.2137 173.923 29.6389 173.494 28.984 173.207C28.2802 172.906 27.5153 172.755 26.7437 172.767C25.963 172.751 25.1891 172.908 24.4833 173.226C23.8354 173.519 23.2678 173.952 22.8257 174.489C22.3679 175.062 22.0271 175.711 21.8211 176.403C21.568 177.236 21.4461 178.101 21.4594 178.968Z"
            fill="white"
          />
          <path
            d="M49.4448 172.671C47.3568 172.671 45.8521 173.229 44.9307 174.345C43.9386 175.675 43.4506 177.296 43.5486 178.933C43.5374 179.797 43.6511 180.658 43.8866 181.492C44.0835 182.208 44.4211 182.881 44.8809 183.473C45.3436 184.026 45.9324 184.467 46.6011 184.762C47.3575 185.076 48.1745 185.23 48.9974 185.214C49.4945 185.214 49.9221 185.214 50.2701 185.214C50.5841 185.203 50.8966 185.168 51.2047 185.108V178.433H55.6692V187.927C54.8313 188.214 53.9698 188.433 53.0939 188.581C51.6507 188.833 50.1854 188.949 48.719 188.927C47.3363 188.945 45.9618 188.72 44.6622 188.263C43.4927 187.854 42.4327 187.197 41.56 186.339C40.6737 185.447 39.9956 184.381 39.5713 183.213C39.0814 181.854 38.8424 180.421 38.8654 178.981C38.8378 177.523 39.1011 176.073 39.641 174.711C40.1183 173.536 40.8391 172.469 41.7588 171.575C42.6581 170.722 43.7365 170.066 44.9207 169.651C46.1612 169.205 47.475 168.98 48.7985 168.987C49.647 168.981 50.4948 169.039 51.334 169.161C52.0057 169.26 52.6699 169.401 53.3226 169.584C53.7819 169.711 54.2306 169.872 54.6649 170.065C55.0129 170.228 55.2714 170.363 55.4206 170.449L54.1379 173.912C53.4649 173.573 52.7588 173.299 52.03 173.095C51.1944 172.832 50.3233 172.689 49.4448 172.671Z"
            fill="white"
          />
          <path
            d="M59.87 188.93V170.037H71.9025V173.607H63.863V177.31H71.0093V180.804H63.863V185.359H72.4743V188.93H59.87Z"
            fill="white"
          />
          <path
            d="M90.3303 170.037V173.664H84.5976V188.93H80.3076V173.664H74.575V170.037H90.3303Z"
            fill="white"
          />
          <path
            d="M104.955 170.037H109.237V188.93H104.955V180.899H97.7642V188.92H93.482V170.037H97.7642V177.253H104.955V170.037Z"
            fill="white"
          />
          <path
            d="M113.439 188.93V170.037H126.42V173.607H117.753V177.31H125.457V180.804H117.753V185.359H127.094V188.93H113.439Z"
            fill="white"
          />
          <path
            d="M135.87 168.987C138.77 168.987 140.983 169.511 142.52 170.56C143.699 171.532 144.489 172.911 144.742 174.438C144.995 175.966 144.693 177.535 143.893 178.849C143.204 179.772 142.277 180.478 141.215 180.887C141.602 181.382 142.008 181.946 142.433 182.579C142.858 183.222 143.284 183.875 143.699 184.557C144.115 185.24 144.521 185.962 144.908 186.714C145.294 187.466 145.661 188.207 146 188.93H141.167L140.084 186.951C139.727 186.288 139.35 185.645 138.973 184.973C138.596 184.3 138.209 183.756 137.842 183.192C137.474 182.628 137.098 182.124 136.721 181.669H134.584V188.88H130.244V169.501C131.202 169.305 132.17 169.17 133.144 169.096C134.169 169.027 135.077 168.987 135.87 168.987ZM136.121 172.776H135.271L134.565 172.835V178.187H135.764C136.965 178.273 138.169 178.059 139.272 177.563C139.626 177.344 139.913 177.028 140.099 176.65C140.285 176.272 140.364 175.848 140.326 175.427C140.358 175.016 140.276 174.604 140.087 174.239C139.899 173.875 139.613 173.573 139.263 173.369C138.279 172.909 137.2 172.705 136.121 172.776Z"
            fill="white"
          />
          <path
            d="M65.5758 162.644C65.0199 161.807 64.4325 160.987 63.7927 160.168C63.1635 159.369 62.4843 158.599 61.7579 157.863V162.644H59.87V155.342H61.4223C61.695 155.576 61.9887 155.864 62.3243 156.242C62.6599 156.62 62.9851 156.945 63.3207 157.323C63.6564 157.701 63.992 158.106 64.3696 158.511L65.3135 159.7V155.387H67.2225V162.689L65.5758 162.644Z"
            fill="white"
          />
          <path
            d="M47.2661 155.342H49.3668V162.689H47.2661V155.342Z"
            fill="white"
          />
          <path
            d="M98.7335 155.342V156.755H96.0598V162.689H94.0546V156.755H91.381V155.342H98.7335Z"
            fill="white"
          />
          <path
            d="M79.8272 155.342H81.9279V162.689H79.8272V155.342Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="flex items-center min-h-screen w-full text-black z-1">
        <div className="mt-20 ml-10 mr-10">
          <p className="text-frontpage-nav-lg nav:text-frontpage-lg text-primary-orange font-primary-bold uppercase font-bold">
            om init together
          </p>
          <p className="tesxt-16 text-primary-orange font-primary-bold font-bold">
            – Ønsker du at samle penge ind til et godt formål?
          </p>
          <p>
            Ønsker din arbejdsplads at lave et medarbejderløb med et godt formål
            som dagsorden?
          </p>
          <ul className="mt-10 ml-5 font-primary-bold list-disc">
            <li>
              Mangler din forening en naturlegeplads til lokalområdets børn?
            </li>
            <li>
              Savner din efterskole et shelter eleverne kan overnatte i, når de
              har været på skovtur?
            </li>
            <li>
              Ønsker du at give dine elever på skolen en god oplevelse ved at
              samle ind til vandprojekter i Afrika?
            </li>
          </ul>
          <p className="mt-10 font-bold">
            Init-together er svaret på alle ovenstående spørgsmål
          </p>
        </div>
      </div>
      <div className="items-end max-h-screen min-h-screen w-full bg-about-well-1 bg-blend-luminosity bg-no-repeat bg-center bg-cover text-white bg-primary-orange z-1 nav:hidden flex"></div>
      <div className="flex w-full text-black z-1">
        <div className="mt-20 ml-10 mr-10">
          <p className="font-bold">Hvad går vores koncept ud på?</p>
          <p>
            Ønsker din arbejdsplads at lave et medarbejderløb med et godt formål
            som dagsorden?
          </p>
          <ul className="mt-10 ml-5 font-primary-bold list-disc">
            <li className="mt-5">
              Kort og godt er Init-Together et løbesoftware som er designet til
              at indsamle donationer til et godt formål gennem løbeaktivitet og
              private donationer.
            </li>
            <li className="mt-7">
              Hver løber skal forsøge at løbe så langt som muligt, typisk i
              4,6,12 eller 24 timer på en opmålt rute af mellem 3-5 km pr.
              omgang.
            </li>
            <li className="mt-7">
              På Init-Together opretter du blot et løb, tilføjer løbere samt et
              projekt, i er ved at samle ind til.
            </li>
            <li className="mt-7">
              Hver løber modtager et unikt link denne kan sende til sit netværk
              af potentielle donatorer (bidragsydere/venner og familie). Disse
              kan donere et kontant beløb pr. km. løberen får tilbagelagt til
              det pågældende løb.
            </li>
            <li className="mt-7">
              Såfremt et firma vælger at afholde løbet, kan firmaet også
              beslutte sig for at donere x antal kroner pr. kilometer de ansatte
              løber til et godt formål.
            </li>
            <li className="mt-7">
              Til dit løb oprettes der en webpage, der viser diverse
              statistikker i forbindelse med afviklingen af jeres løb.
            </li>
            <li className="mt-7">
              Det vil være muligt at smide denne webpage på diverse fladskærme,
              projektorer eller lignende som kan bruges til at vise hvordan det
              går med indsamlingen. Samt andre interessante nøgletal som er
              relevante for løbet. (Antal løbne kilometer, personlig
              performance, antal indsamlede kroner, kroner pr. løber og
              lignende)
            </li>
            <li className="mt-7">
              Init-Together laver en unik webpage til dit løb, som alle
              interessenter til løbet kan logge på for at følge udviklingen af
              løbet.
            </li>
            <li className="mt-7">
              Løbet kan afvikles et hvilket som helst sted i Danmark (eller
              udland) og kræver blot kontakt til internettet, gennem enten
              mobildatanetværk eller WiFi.
            </li>
            <li className="mt-7">
              Hvis ønsket er det muligt at vise, hvilke løbere der har løbet
              flest km samt hvilke løbere der har skaffet flest donationer.
            </li>
            <li className="mt-7">
              Vi arbejder sammen med NGOen Lovespring, der skaber rent
              drikkevand i Afrika, vi synes, de har et godt formål og dem
              anbefaler vi, at I overvejer at støtte gennem jeres løb.
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="items-end max-h-screen min-h-screen w-full bg-about-well-1 bg-blend-luminosity bg-no-repeat bg-center bg-cover text-white bg-primary-orange z-1 hidden nav:flex"></div>
        <div className="flex w-full text-black z-1">
          <div className="mt-20 ml-10 mr-10">
            <p>
              Ønsker du at bruge eller at høre mere om Init-Together? <br></br>
              <span className="font-bold text-primary-orange">
                <Link to="/contact">Kontakt os</Link>
              </span>
              , så tager vi telefonisk kontakt til dig og forklarer hvordan
              løbesoftwaren fungerer.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="nav:col-span-2 flex items-start min-h-screen w-full text-black z-1">
        <div className="mt-20 ml-10 mr-10">
          <p className="text-frontpage-nav-lg nav:text-frontpage-lgtext-frontpage-lg text-primary-orange font-primary-bold uppercase font-bold">
            TIlmeldingsforløbet
          </p>
          <p className="tesxt-16 text-primary-orange font-primary-bold font-bold">
            – Hvad skal du gøre for at være med i fællesløbet?
          </p>
        </div>
      </div> */}

      <div className="items-end max-h-screen min-h-screen w-full bg-about-well-2 bg-blend-luminosity bg-no-repeat bg-center bg-cover text-white bg-primary-blue z-1 mt-10"></div>
      <div className="grid grid-cols-1 place-items-center  min-h-600 text-center bg-primary-orange text-white mt-10">
        <div className="pt-10 pl-10 pr-10 pb-10">
          <p className="inline-block text-32 nav:text-52 font-bold uppercase">
            Brønd i Ngarenaibo, Tanzania - 2019
          </p>
          <p className="mt-4 font-bold">
            Tak for alle donationerne til en brønd i Ngarenaibo, Tanzania
          </p>
        </div>
      </div>
      <div className="items-end max-h-screen min-h-screen w-full bg-about-well-3 bg-blend-luminosity bg-no-repeat bg-center bg-cover text-white bg-primary-blue z-1 nav:hidden mt-10"></div>
      <div className="grid grid-cols-1 place-items-center min-h-600 text-center bg-primary-orange text-white">
        <div className="pt-10 pl-10 pr-10 pb-10">
          <p className="inline-block text-32 nav:text-52 font-bold uppercase">
            Brønd til Zhiyinda Community Nigeria
          </p>
          <p className="mt-4 font-bold">
            Denne brønd er sponsoreret af en af LoveSprings vigtige
            samarbejdspartnere BarutRen og er udført i samarbejde med
          </p>
        </div>
      </div>
      <div className="items-end max-h-screen min-h-screen w-full bg-about-well-3 bg-blend-luminosity bg-no-repeat bg-center bg-cover text-white bg-primary-blue z-1 hidden nav:flex"></div>
    </div>
  );
};

export default About;
