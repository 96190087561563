import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const RedirectDry = () => {
   const { url_link } = useParams()
   const { slug } = useParams()

   useEffect(() => {
      window.location.href = "https://app.init-together.dk/" + url_link + "/" + slug;
   }, [url_link]);

   return <h5>Redirecting...</h5>;
};

export default RedirectDry;