import React from "react";
import Dropdown from "./Dropdown";

const Header = ({ isOpen, toggle }) => {
  return (
    <div className="bg-header-bg -no-repeat bg-cover absolute bg-center w-full max-w-3xl h-header-img-sm nav:h-header-img-md nav-lg:h-header-img-lg -z-1">
      <Dropdown isOpen={isOpen} toggle={toggle} />
    </div>
  );
};

export default Header;
