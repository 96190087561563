import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Masterlayout = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 900 && isOpen) {
        setIsOpen(false);
        console.log("No dropdownmenu at this media query");
      }
    };
    window.addEventListener("resize", hideMenu);

    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  });

  return (
    <div className="App max-w-3xl m-auto">
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Header isOpen={isOpen} toggle={toggle} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Masterlayout;
