import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Pagenotfound = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 900 && isOpen) {
        setIsOpen(false);
        console.log("No dropdownmenu at this media query");
      }
    };
    window.addEventListener("resize", hideMenu);

    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  });

  return (
    <div className="App max-w-3xl m-auto">
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Header isOpen={isOpen} toggle={toggle} />
      <div className="text-center font-primary-bold text-primary-orange font-bold mt-200 mb-200 text-24 nav:mb-100 nav:mt-400 nav:text-48  ">
        <p>Page not found</p>
      </div>
      <Footer />
    </div>
  );
};

export default Pagenotfound;
