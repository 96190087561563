import React from "react";
import { Link, NavLink } from "react-router-dom";

const Navbar = ({ toggle, isOpen }) => {
  return (
    <nav
      className="flex justify-end text-white relative w-full showdow-sm font-menu uppercase font-primary-bold text-menu h-header-bg mb-header-b z-50"
      role="navigation"
    >
      <div className="mt-8 hidden nav:block">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "mr-14 pt-6 text-primary-orange border-primary-orange border-t-8"
              : "mr-14 hover:pt-6 hover:text-primary-orange hover:border-primary-orange hover:border-t-8"
          }
          to="/"
        >
          Menu
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "mr-14 pt-6 text-primary-orange border-primary-orange border-t-8"
              : "mr-14 hover:pt-6 hover:text-primary-orange hover:border-primary-orange hover:border-t-8"
          }
          to="/about"
        >
          Om os
        </NavLink>
        <a href="https://app.init-together.dk" className="mr-14 hover:pt-6 hover:text-primary-orange hover:border-primary-orange hover:border-t-8">
          Events
        </a>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "mr-14 pt-6 text-primary-orange border-primary-orange border-t-8"
              : "mr-14 hover:pt-6 hover:text-primary-orange hover:border-primary-orange hover:border-t-8"
          }
          to="/contact"
        >
          Kontakt
        </NavLink>
      </div>
      <div className="mr-14 mt-5 shadow h-10 hidden">
        <input
          className="bg-transparent border-white border-b-2 rounded py-2 drop-shadow-nav-search px-4 text-primary-orange"
          type="text"
          placeholder="&#x1F50D;&#xFE0E;"
        ></input>
      </div>
      <div className="hidden nav:block ">
        <Link className="p-0" to="/admin/login">
          {/* <svg
            className="inline right-140"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2C0 0.895432 0.895431 0 2 0H60V58C60 59.1046 59.1046 60 58 60H2C0.895432 60 0 59.1046 0 58V2Z"
              fill="#FF4D00"
            />
            <ellipse
              cx="21.5338"
              cy="31.1977"
              rx="4.68167"
              ry="4.9136"
              fill="white"
              stroke="white"
            />
            <path
              d="M25.045 27.5124L29.1415 23.213M30.8971 21.3704L29.1415 23.213M29.1415 23.213L32.0676 26.284"
              stroke="white"
            />
            <path
              d="M10.1695 36.6V28.14H11.7055V35.292H15.7615V36.6H10.1695ZM32.599 36.72C31.663 36.72 30.871 36.544 30.223 36.192C29.583 35.84 29.095 35.34 28.759 34.692C28.423 34.044 28.255 33.276 28.255 32.388C28.255 31.492 28.427 30.72 28.771 30.072C29.115 29.416 29.611 28.912 30.259 28.56C30.907 28.2 31.683 28.02 32.587 28.02C33.179 28.02 33.735 28.108 34.255 28.284C34.775 28.452 35.207 28.688 35.551 28.992L35.035 30.192C34.659 29.896 34.275 29.684 33.883 29.556C33.491 29.428 33.059 29.364 32.587 29.364C31.691 29.364 31.011 29.624 30.547 30.144C30.091 30.664 29.863 31.412 29.863 32.388C29.863 33.372 30.099 34.12 30.571 34.632C31.043 35.144 31.743 35.4 32.671 35.4C32.935 35.4 33.203 35.38 33.475 35.34C33.755 35.292 34.031 35.224 34.303 35.136V33.168H32.359V32.028H35.587V36.096C35.195 36.288 34.731 36.44 34.195 36.552C33.659 36.664 33.127 36.72 32.599 36.72ZM37.8469 36.6V28.14H39.3829V36.6H37.8469ZM41.7019 36.6V28.14H42.8539L47.4019 34.044V28.14H48.8299V36.6H47.6899L43.1299 30.672V36.6H41.7019Z"
              fill="white"
            />
          </svg> */}
          <svg
            width="60"
            height="60"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.26667C0 0.567105 0.567106 0 1.26667 0H40V38.7333C40 39.4329 39.4329 40 38.7333 40H1.26667C0.567105 40 0 39.4329 0 38.7333V1.26667Z"
              fill="#FF4D00"
            />
            <ellipse
              cx="16.3749"
              cy="22.3058"
              rx="4.16667"
              ry="4.37308"
              fill="white"
              fillOpacity="0.25"
              stroke="white"
              strokeWidth="0.76"
            />
            <path
              d="M19.5 19.0259L23.1458 15.1995M24.7083 13.5596L23.1458 15.1995M23.1458 15.1995L25.75 17.9327"
              stroke="white"
              strokeWidth="0.76"
              strokeLinecap="round"
            />
          </svg>
        </Link>
      </div>

      <div
        className={isOpen ? "hidden" : "mr-1 cursor-pointer nav:hidden"}
        onClick={toggle}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="2" fill="#FF4D00" />
          <rect
            x="10"
            y="14.0002"
            width="20"
            height="1.33333"
            rx="0.666667"
            fill="white"
          />
          <rect
            x="10"
            y="18.667"
            width="20"
            height="1.33333"
            rx="0.666667"
            fill="white"
          />
          <rect
            x="10"
            y="24.0002"
            width="20"
            height="1.33333"
            rx="0.666667"
            fill="white"
          />
        </svg>
      </div>
      <div
        className={isOpen ? "mr-1 cursor-pointer nav:hidden" : "hidden"}
        onClick={toggle}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="2" fill="#FF4D00" />
          <rect
            x="12.6667"
            y="26.8088"
            width="20"
            height="1.33333"
            rx="0.666667"
            transform="rotate(-45 12.6667 26.8088)"
            fill="white"
          />
          <rect
            x="13.8186"
            y="12.8759"
            width="20"
            height="1.33333"
            rx="0.666667"
            transform="rotate(45 13.8186 12.8759)"
            fill="white"
          />
        </svg>
      </div>
      <div className="mr-1 cursor-pointer nav:hidden">
        <Link to="/admin/login">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.26667C0 0.567105 0.567106 0 1.26667 0H40V38.7333C40 39.4329 39.4329 40 38.7333 40H1.26667C0.567105 40 0 39.4329 0 38.7333V1.26667Z"
              fill="#FF4D00"
            />
            <ellipse
              cx="16.3749"
              cy="22.3058"
              rx="4.16667"
              ry="4.37308"
              fill="white"
              fillOpacity="0.25"
              stroke="white"
              strokeWidth="0.76"
            />
            <path
              d="M19.5 19.0259L23.1458 15.1995M24.7083 13.5596L23.1458 15.1995M23.1458 15.1995L25.75 17.9327"
              stroke="white"
              strokeWidth="0.76"
              strokeLinecap="round"
            />
          </svg>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
