import React from "react";
import fb from "../assets/images/fb.png";
import ig from "../assets/images/ig.png";

const Footer = () => {
  return (
    <div className="grid grid-cols-1 nav:grid-cols-2 align-text-bottom bg-footer-bg -no-repeat bg-cover bg-top w-container mt-20 text-white font-primary-bold pl-10 pr-10 text-18">
      <div className=" h-footer-img-sm nav:h-footer-img-md nav-lg:h-footer-img-lg"></div>
      <div></div>
      <div className="mb-5 nav:mb-20 text-center nav:text-left">
        <p>
          Kontakt os: <br></br>E-mail: info@init-together.dk <br></br>Tlfnr.:
          6059 7070 <br></br> CVR: 40877223
        </p>
      </div>
      <div className="mb-10 nav:mt-12 text-center nav:text-right inline-block">
      {/*   Privatlivspolitik <span className="text-primary-orange">|</span> Cookies */}
        <span className="nav:hidden">
          <br></br>
          <br></br>
        </span>
        <img
          className="inline-block m-auto nav:ml-6"
          src={fb}
          alt="Facebook-logo"
        ></img>
        <img
          className="inline-block m-auto ml-6"
          src={ig}
          alt="Instagram-logo"
        ></img>
      </div>
    </div>
  );
};

export default Footer;
