import React from "react";
import { NavLink } from "react-router-dom";

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <div
      className={
        isOpen
          ? "grid grid-rows-5 text-center items-center bg-primary-blue text-white font-primary-bold uppercase pt-nav-padding mb-header-dropdown-b"
          : "hidden"
      }
      onClick={toggle}
    >
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "text-primary-orange z-50"
            : "hover:text-primary-orange z-50"
        }
        to="/"
      >
        Menu
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "text-primary-orange z-50"
            : "hover:text-primary-orange z-50"
        }
        to="/about"
      >
        Om os
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "text-primary-orange z-50"
            : "hover:text-primary-orange z-50"
        }
        to="/events"
      >
        Events
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "text-primary-orange z-50"
            : "hover:text-primary-orange z-50"
        }
        to="/contact"
      >
        Kontakt
      </NavLink>
    </div>
  );
};

export default Dropdown;
