import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Echo from "laravel-echo";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Masterlayout from "./domains/landingpage/pages/Masterlayout";
import Pagenotfound from "./domains/landingpage/pages/Pagenotfound";
import Content from "./domains/landingpage/pages/frontpage";
import About from "./domains/landingpage/pages/About";
import Events from "./domains/landingpage/pages/Events";
import Contact from "./domains/landingpage/pages/Contact";
import Adminmasterlayout from "./domains/admin/pages/Masterlayout";
import Redirect from "./domains/landingpage/components/Rederect";
import RedirectDry from "./domains/landingpage/components/RederectDry";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "y28qXCITc7cs",
  wsHost: "websocket.inittogether.dk",
  enabledTransports: ["ws", "wss"],
  wsPort: 8443,
  wssPort: 8443,
  disableStats: false,
  forceTLS: true,
  encrypted: true,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Masterlayout />}>
          <Route path="/" exact element={<Content />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/events" exact strict element={<Events />}>
            <Route path="/events/category:id" element={<Events />}></Route>
          </Route>
          <Route path="/contact" exact element={<Contact />} />
        </Route>
        <Route path="/admin" element={<Adminmasterlayout />}> 
          <Route path="/admin" exact element={<Pagenotfound />} />
        </Route>
        <Route path="/admin/login" element={<Redirect url={ "https://admin.init-together.dk" } />} />
        <Route path="/redirect/:url_link/:slug" element={<RedirectDry/>} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
