import React, { useEffect } from "react";

const Redirect = ( props ) => {

   useEffect(() => {
      window.location.href = props.url;
   }, [props.url]);

   return <h5>Redirecting...</h5>;
};

export default Redirect;