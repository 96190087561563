import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactGA from "react-ga";
import DayJS from 'react-dayjs';


const Content = () => {
  useEffect(() => {
    fetchTeams();

    ReactGA.event({
      category: "LoadPage",
      action: "Frontpage",
    });
  
    window.Echo.connect()
    window.Echo.channel("Events").listen(".newEvent", (event) => {
      fetchTeams();
      console.log("FrontpageEvets - Update");
    });
    return () => {
      window.Echo.disconnect()
  }
  }, []);

  const [teams, setTeams] = useState([]);

  const fetchTeams = async () => {
    const data = await fetch("https://api.inittogether.dk/api/v1/event/all");

    const teams = await data.json();
    setTeams(teams);
  };

  

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div className="grid grid-cols-1 place-items-center bg-frontpage-bg min-h-screen bg-no-repeat text-white text-center bg-cover bg-primary-blue bg-center z-1">
        <div className="nav:hidden">
          <svg
            className="m-auto"
            width="169"
            height="248"
            viewBox="0 0 269 348"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M201.277 17.7435C191.082 23.5766 181.708 30.7227 173.399 38.996C150.418 61.7954 136.538 92.0943 134.329 124.279C132.121 92.0943 118.24 61.7954 95.2594 38.996C70.007 13.9829 35.784 -0.0440729 0.118159 0.000602453V39.3859H0.353745C24.4221 39.3947 47.5934 48.4586 65.2027 64.7528C82.812 81.047 93.5516 103.361 95.2594 127.204C95.4165 129.335 95.495 131.48 95.495 133.638C95.495 135.9 95.4165 138.122 95.2594 140.306C93.6608 162.908 83.9006 184.177 67.7733 200.203L67.5377 200.476L67.2629 200.71C51.1675 216.672 29.8326 226.36 7.14667 228.007C4.86925 228.007 2.59191 228.241 0.235952 228.241H0.000366211V266.456H13.5863C32.564 264.619 50.9227 258.755 67.4199 249.259C77.5994 243.403 86.9706 236.259 95.2987 228.007C103.608 219.736 110.801 210.43 116.699 200.32C126.942 182.714 132.986 163.011 134.368 142.724C136.577 174.909 150.457 205.208 173.438 228.007C181.758 236.268 191.13 243.413 201.317 249.259C217.803 258.748 236.147 264.613 255.111 266.456H268.579V228.241H268.304C265.988 228.241 263.671 228.241 261.394 228.007C238.721 226.351 217.402 216.664 201.317 200.71L201.042 200.476L200.806 200.203C184.661 184.189 174.898 162.913 173.32 140.306C173.124 138.122 173.045 135.9 173.045 133.638C173.045 131.493 173.045 129.348 173.32 127.204C175.027 103.368 185.762 81.0592 203.363 64.766C220.964 48.4728 244.125 39.4045 268.187 39.3859H268.461V0.000602453C244.883 -0.069914 221.706 6.05115 201.277 17.7435Z"
              fill="white"
            />
            <path
              d="M28.9833 312.797V319.469H18.4376V347.552H10.5458V319.469H0V312.797H28.9833Z"
              fill="white"
            />
            <path
              d="M67.6225 329.226C67.6748 331.928 67.192 334.616 66.1995 337.148C65.3556 339.291 64.0457 341.241 62.3555 342.869C60.7041 344.428 58.7183 345.629 56.5341 346.389C54.2026 347.176 51.7447 347.569 49.2712 347.551C46.8401 347.567 44.4252 347.173 42.1377 346.389C39.9446 345.625 37.9475 344.425 36.2793 342.869C34.554 341.251 33.2119 339.3 32.3429 337.148C31.3408 334.618 30.8577 331.929 30.9199 329.226C30.8669 326.514 31.3691 323.817 32.3983 321.287C33.3086 319.129 34.6806 317.174 36.4271 315.549C38.0857 313.989 40.0776 312.788 42.267 312.028C44.5111 311.249 46.8826 310.855 49.2712 310.866C51.6963 310.851 54.1051 311.244 56.3863 312.028C58.5854 312.78 60.5851 313.982 62.2447 315.549C63.9621 317.179 65.3028 319.134 66.181 321.287C67.1887 323.822 67.678 326.517 67.6225 329.226ZM39.4764 329.226C39.4549 330.798 39.6791 332.364 40.1417 333.874C40.513 335.15 41.1407 336.345 41.9898 337.394C42.7988 338.383 43.8443 339.174 45.0391 339.7C46.3425 340.27 47.7636 340.552 49.1973 340.527C50.6181 340.543 52.0251 340.261 53.3185 339.7C54.5274 339.18 55.5863 338.389 56.4048 337.394C57.2469 336.341 57.8738 335.147 58.2528 333.874C58.7154 332.364 58.9396 330.798 58.9181 329.226C58.9392 327.643 58.715 326.065 58.2528 324.544C57.8813 323.241 57.2543 322.016 56.4048 320.935C55.5807 319.946 54.5233 319.156 53.3185 318.629C52.0238 318.074 50.6168 317.798 49.1973 317.819C47.7611 317.789 46.3374 318.079 45.0391 318.664C43.8472 319.203 42.8032 319.999 41.9898 320.988C41.1476 322.041 40.5207 323.235 40.1417 324.509C39.6761 326.041 39.452 327.631 39.4764 329.226Z"
              fill="white"
            />
            <path
              d="M90.9579 317.643C87.1167 317.643 84.3487 318.67 82.6537 320.722C80.8288 323.169 79.9309 326.15 80.1113 329.162C80.0906 330.752 80.2999 332.336 80.7331 333.869C81.0953 335.186 81.7163 336.424 82.5623 337.514C83.4133 338.531 84.4965 339.342 85.7266 339.885C87.1182 340.463 88.621 340.746 90.1348 340.717C91.0494 340.717 91.8359 340.717 92.4761 340.717C93.0536 340.696 93.6286 340.631 94.1954 340.522V328.242H102.408V345.707C100.867 346.236 99.282 346.638 97.6707 346.91C95.0157 347.374 92.3203 347.588 89.6226 347.547C87.0792 347.58 84.5506 347.166 82.1599 346.326C80.0084 345.573 78.0585 344.364 76.453 342.787C74.8227 341.145 73.5753 339.184 72.7948 337.036C71.8935 334.535 71.4539 331.9 71.4962 329.251C71.4454 326.568 71.9298 323.9 72.9229 321.395C73.801 319.235 75.1269 317.272 76.8189 315.626C78.4733 314.057 80.4569 312.85 82.6354 312.087C84.9173 311.266 87.3343 310.853 89.769 310.867C91.3299 310.855 92.8894 310.962 94.4332 311.185C95.6688 311.367 96.8908 311.628 98.0914 311.964C98.9364 312.198 99.7618 312.494 100.561 312.848C101.201 313.149 101.676 313.397 101.951 313.556L99.5913 319.926C98.3532 319.302 97.0543 318.798 95.7136 318.422C94.1764 317.938 92.574 317.676 90.9579 317.643Z"
              fill="white"
            />
            <path
              d="M110.136 347.552V312.797H132.271V319.365H117.481V326.176H130.628V332.605H117.481V340.984H133.323V347.552H110.136Z"
              fill="white"
            />
            <path
              d="M166.17 312.797V319.469H155.624V347.552H147.733V319.469H137.187V312.797H166.17Z"
              fill="white"
            />
            <path
              d="M193.074 312.797H200.951V347.552H193.074V332.779H179.845V347.535H171.968V312.797H179.845V326.072H193.074V312.797Z"
              fill="white"
            />
            <path
              d="M208.681 347.552V312.797H232.56V319.365H216.617V326.176H230.788V332.605H216.617V340.984H233.8V347.552H208.681Z"
              fill="white"
            />
            <path
              d="M249.944 310.866C255.279 310.866 259.35 311.83 262.178 313.759C264.346 315.547 265.8 318.085 266.265 320.894C266.73 323.703 266.174 326.59 264.703 329.009C263.437 330.706 261.73 332.005 259.777 332.757C260.488 333.667 261.235 334.704 262.018 335.869C262.8 337.052 263.582 338.253 264.347 339.508C265.112 340.764 265.858 342.093 266.57 343.476C267.281 344.859 267.957 346.223 268.579 347.552H259.688L257.697 343.912C257.039 342.693 256.345 341.51 255.652 340.273C254.959 339.035 254.247 338.034 253.572 336.997C252.896 335.96 252.202 335.032 251.509 334.195H247.579V347.461H239.596V311.812C241.357 311.451 243.139 311.202 244.93 311.066C246.815 310.939 248.486 310.866 249.944 310.866ZM250.407 317.835H248.842L247.544 317.945V327.789H249.749C251.959 327.947 254.174 327.554 256.203 326.643C256.854 326.24 257.381 325.657 257.724 324.962C258.066 324.267 258.211 323.488 258.141 322.712C258.201 321.956 258.049 321.199 257.702 320.528C257.356 319.858 256.83 319.302 256.185 318.927C254.377 318.081 252.391 317.706 250.407 317.835Z"
              fill="white"
            />
            <path
              d="M120.632 299.198C119.61 297.657 118.529 296.15 117.352 294.643C116.195 293.172 114.945 291.757 113.609 290.402V299.198H110.136V285.765H112.991C113.493 286.195 114.033 286.725 114.651 287.421C115.268 288.117 115.866 288.713 116.484 289.409C117.101 290.104 117.719 290.85 118.413 291.595L120.15 293.781V285.847H123.661V299.281L120.632 299.198Z"
              fill="white"
            />
            <path
              d="M86.95 285.765H90.8144V299.281H86.95V285.765Z"
              fill="white"
            />
            <path
              d="M181.629 285.765V288.364H176.71V299.281H173.021V288.364H168.103V285.765H181.629Z"
              fill="white"
            />
            <path
              d="M146.849 285.765H150.713V299.281H146.849V285.765Z"
              fill="white"
            />
          </svg>
          <br></br>
          <p className="pt-10 font-primary-bold text-24 font-bold">
            FÆLLES LØB, FÆLLS MÅL!
          </p>
          <p className="pt-2 font-primary-regular font-bold w-72 text-frontpage-md m-auto">
            – Vi visualiserer donationer live!
          </p>
          <p className="pt-2 font-primary-bold w-72 text-frontpage-sm m-auto items-end">
            Øge aktivitetsniveau igennem et fællesløb med donationer. Skaf
            donatorer der vil støtte med x antal kr. pr. kolometer der løbes.
          </p>
        </div>

        <div className="hidden nav:inline-block">
          <svg
            className="m-auto"
            width="269"
            height="348"
            viewBox="0 0 269 348"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M201.277 17.7435C191.082 23.5766 181.708 30.7227 173.399 38.996C150.418 61.7954 136.538 92.0943 134.329 124.279C132.121 92.0943 118.24 61.7954 95.2594 38.996C70.007 13.9829 35.784 -0.0440729 0.118159 0.000602453V39.3859H0.353745C24.4221 39.3947 47.5934 48.4586 65.2027 64.7528C82.812 81.047 93.5516 103.361 95.2594 127.204C95.4165 129.335 95.495 131.48 95.495 133.638C95.495 135.9 95.4165 138.122 95.2594 140.306C93.6608 162.908 83.9006 184.177 67.7733 200.203L67.5377 200.476L67.2629 200.71C51.1675 216.672 29.8326 226.36 7.14667 228.007C4.86925 228.007 2.59191 228.241 0.235952 228.241H0.000366211V266.456H13.5863C32.564 264.619 50.9227 258.755 67.4199 249.259C77.5994 243.403 86.9706 236.259 95.2987 228.007C103.608 219.736 110.801 210.43 116.699 200.32C126.942 182.714 132.986 163.011 134.368 142.724C136.577 174.909 150.457 205.208 173.438 228.007C181.758 236.268 191.13 243.413 201.317 249.259C217.803 258.748 236.147 264.613 255.111 266.456H268.579V228.241H268.304C265.988 228.241 263.671 228.241 261.394 228.007C238.721 226.351 217.402 216.664 201.317 200.71L201.042 200.476L200.806 200.203C184.661 184.189 174.898 162.913 173.32 140.306C173.124 138.122 173.045 135.9 173.045 133.638C173.045 131.493 173.045 129.348 173.32 127.204C175.027 103.368 185.762 81.0592 203.363 64.766C220.964 48.4728 244.125 39.4045 268.187 39.3859H268.461V0.000602453C244.883 -0.069914 221.706 6.05115 201.277 17.7435Z"
              fill="white"
            />
            <path
              d="M28.9833 312.797V319.469H18.4376V347.552H10.5458V319.469H0V312.797H28.9833Z"
              fill="white"
            />
            <path
              d="M67.6225 329.226C67.6748 331.928 67.192 334.616 66.1995 337.148C65.3556 339.291 64.0457 341.241 62.3555 342.869C60.7041 344.428 58.7183 345.629 56.5341 346.389C54.2026 347.176 51.7447 347.569 49.2712 347.551C46.8401 347.567 44.4252 347.173 42.1377 346.389C39.9446 345.625 37.9475 344.425 36.2793 342.869C34.554 341.251 33.2119 339.3 32.3429 337.148C31.3408 334.618 30.8577 331.929 30.9199 329.226C30.8669 326.514 31.3691 323.817 32.3983 321.287C33.3086 319.129 34.6806 317.174 36.4271 315.549C38.0857 313.989 40.0776 312.788 42.267 312.028C44.5111 311.249 46.8826 310.855 49.2712 310.866C51.6963 310.851 54.1051 311.244 56.3863 312.028C58.5854 312.78 60.5851 313.982 62.2447 315.549C63.9621 317.179 65.3028 319.134 66.181 321.287C67.1887 323.822 67.678 326.517 67.6225 329.226ZM39.4764 329.226C39.4549 330.798 39.6791 332.364 40.1417 333.874C40.513 335.15 41.1407 336.345 41.9898 337.394C42.7988 338.383 43.8443 339.174 45.0391 339.7C46.3425 340.27 47.7636 340.552 49.1973 340.527C50.6181 340.543 52.0251 340.261 53.3185 339.7C54.5274 339.18 55.5863 338.389 56.4048 337.394C57.2469 336.341 57.8738 335.147 58.2528 333.874C58.7154 332.364 58.9396 330.798 58.9181 329.226C58.9392 327.643 58.715 326.065 58.2528 324.544C57.8813 323.241 57.2543 322.016 56.4048 320.935C55.5807 319.946 54.5233 319.156 53.3185 318.629C52.0238 318.074 50.6168 317.798 49.1973 317.819C47.7611 317.789 46.3374 318.079 45.0391 318.664C43.8472 319.203 42.8032 319.999 41.9898 320.988C41.1476 322.041 40.5207 323.235 40.1417 324.509C39.6761 326.041 39.452 327.631 39.4764 329.226Z"
              fill="white"
            />
            <path
              d="M90.9579 317.643C87.1167 317.643 84.3487 318.67 82.6537 320.722C80.8288 323.169 79.9309 326.15 80.1113 329.162C80.0906 330.752 80.2999 332.336 80.7331 333.869C81.0953 335.186 81.7163 336.424 82.5623 337.514C83.4133 338.531 84.4965 339.342 85.7266 339.885C87.1182 340.463 88.621 340.746 90.1348 340.717C91.0494 340.717 91.8359 340.717 92.4761 340.717C93.0536 340.696 93.6286 340.631 94.1954 340.522V328.242H102.408V345.707C100.867 346.236 99.282 346.638 97.6707 346.91C95.0157 347.374 92.3203 347.588 89.6226 347.547C87.0792 347.58 84.5506 347.166 82.1599 346.326C80.0084 345.573 78.0585 344.364 76.453 342.787C74.8227 341.145 73.5753 339.184 72.7948 337.036C71.8935 334.535 71.4539 331.9 71.4962 329.251C71.4454 326.568 71.9298 323.9 72.9229 321.395C73.801 319.235 75.1269 317.272 76.8189 315.626C78.4733 314.057 80.4569 312.85 82.6354 312.087C84.9173 311.266 87.3343 310.853 89.769 310.867C91.3299 310.855 92.8894 310.962 94.4332 311.185C95.6688 311.367 96.8908 311.628 98.0914 311.964C98.9364 312.198 99.7618 312.494 100.561 312.848C101.201 313.149 101.676 313.397 101.951 313.556L99.5913 319.926C98.3532 319.302 97.0543 318.798 95.7136 318.422C94.1764 317.938 92.574 317.676 90.9579 317.643Z"
              fill="white"
            />
            <path
              d="M110.136 347.552V312.797H132.271V319.365H117.481V326.176H130.628V332.605H117.481V340.984H133.323V347.552H110.136Z"
              fill="white"
            />
            <path
              d="M166.17 312.797V319.469H155.624V347.552H147.733V319.469H137.187V312.797H166.17Z"
              fill="white"
            />
            <path
              d="M193.074 312.797H200.951V347.552H193.074V332.779H179.845V347.535H171.968V312.797H179.845V326.072H193.074V312.797Z"
              fill="white"
            />
            <path
              d="M208.681 347.552V312.797H232.56V319.365H216.617V326.176H230.788V332.605H216.617V340.984H233.8V347.552H208.681Z"
              fill="white"
            />
            <path
              d="M249.944 310.866C255.279 310.866 259.35 311.83 262.178 313.759C264.346 315.547 265.8 318.085 266.265 320.894C266.73 323.703 266.174 326.59 264.703 329.009C263.437 330.706 261.73 332.005 259.777 332.757C260.488 333.667 261.235 334.704 262.018 335.869C262.8 337.052 263.582 338.253 264.347 339.508C265.112 340.764 265.858 342.093 266.57 343.476C267.281 344.859 267.957 346.223 268.579 347.552H259.688L257.697 343.912C257.039 342.693 256.345 341.51 255.652 340.273C254.959 339.035 254.247 338.034 253.572 336.997C252.896 335.96 252.202 335.032 251.509 334.195H247.579V347.461H239.596V311.812C241.357 311.451 243.139 311.202 244.93 311.066C246.815 310.939 248.486 310.866 249.944 310.866ZM250.407 317.835H248.842L247.544 317.945V327.789H249.749C251.959 327.947 254.174 327.554 256.203 326.643C256.854 326.24 257.381 325.657 257.724 324.962C258.066 324.267 258.211 323.488 258.141 322.712C258.201 321.956 258.049 321.199 257.702 320.528C257.356 319.858 256.83 319.302 256.185 318.927C254.377 318.081 252.391 317.706 250.407 317.835Z"
              fill="white"
            />
            <path
              d="M120.632 299.198C119.61 297.657 118.529 296.15 117.352 294.643C116.195 293.172 114.945 291.757 113.609 290.402V299.198H110.136V285.765H112.991C113.493 286.195 114.033 286.725 114.651 287.421C115.268 288.117 115.866 288.713 116.484 289.409C117.101 290.104 117.719 290.85 118.413 291.595L120.15 293.781V285.847H123.661V299.281L120.632 299.198Z"
              fill="white"
            />
            <path
              d="M86.95 285.765H90.8144V299.281H86.95V285.765Z"
              fill="white"
            />
            <path
              d="M181.629 285.765V288.364H176.71V299.281H173.021V288.364H168.103V285.765H181.629Z"
              fill="white"
            />
            <path
              d="M146.849 285.765H150.713V299.281H146.849V285.765Z"
              fill="white"
            />
          </svg>
          <br></br>
          <p className="pt-10 font-primary-bold text-frontpage-lg font-bold">
            FÆLLES LØB, FÆLLS MÅL!
          </p>
          <p className="pt-2 font-primary-regular font-bold w-72 text-frontpage-md m-auto">
            – Vi visualiserer donationer live!
          </p>
          <p className="pt-2 font-primary-bold w-72 text-frontpage-sm m-auto items-end">
            Øge aktivitetsniveau igennem et fællesløb med donationer. Skaf
            donatorer der vil støtte med x antal kr. pr. kolometer der løbes.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 nav:grid-cols-2 place-items-center text-center bg-primary-orange text-white">
        <div className="bg-frontpage-tirstrup bg-cover bg-center bg-blend-luminosity bg-primary-orange h-600 w-full"></div>
        <div className="pt-10 pl-10 pr-10 pb-10">
          <p className="inline-block text-32 nav:text-48 font-bold">
            Tirstrup idrætsefterskole
          </p>
          <p className="mt-4">Tak for alle donation</p>
          <ul className="list-disc inline-block text-left ml-10 mt-5">
            <li>24 timers løb</li>
            <li>9.182 km løbet</li>
            <li>217.047kr indsamlet</li>
            <li>4 brønde bygge</li>
          </ul>
        </div>
      </div>
      <div className="mt-20 ml-10 mr-10">
        <p className="text-frontpage-nav-lg nav:text-frontpage-lg text-primary-orange font-primary-bold uppercase font-bold">
          Upcoming Events
        </p>
        <p className="mb-10 mt-5 tesxt-16 text-primary-orange font-primary-bold">
          År 2020 blev der samlet donationsbeløbet ved skoleløbene på ca.
          <br></br> 1 million kr.
        </p>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          autoPlay={false}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          //removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {teams.map((team) => (
            <div
              key={team.id}
              className="text-center mb-6 border-2 h-500 pt-2 pb-10"
            >
               <p className="text-24 font-primary-bold text-primary-orange">
                <DayJS format="DD/MM-YYYY">{team.event_start_date}</DayJS> 
              </p>
              <img
                className="m-auto"
                src={team.img + "?type=resize_crop&w=300&h=200"} 
                alt="logo"
              ></img>
              <p className="text-24 font-primary-bold text-primary-orange">
               {team.name}
              </p>
              <p className="mt-2">
               {team.description}
              </p>
              <br></br>
              <a href={"https://app.init-together.dk/"+team.org_slug+"/"+team.slug} className="mt-2 text-primary-orange">
                Læs mere {">"}
              </a>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Content;
